// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-vehicles-tsx": () => import("./../../../src/pages/vehicles.tsx" /* webpackChunkName: "component---src-pages-vehicles-tsx" */),
  "component---src-templates-vehicle-template-js": () => import("./../../../src/templates/vehicle-template.js" /* webpackChunkName: "component---src-templates-vehicle-template-js" */)
}

