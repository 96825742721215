import Img from "gatsby-image"
import React from "react"
import "./ContentImage.scss"

interface IContentImageProps {
  fluidImg: any
  caption: string
}

const ContentImage: React.FC<IContentImageProps> = props => {
  return (
    <div className="content-image">
      <figure className="content-image--bleed">
        <Img fluid={props.fluidImg} alt={props.caption} />
        <figcaption className="wrapper f-mono content-image__caption">
          {props.caption}
        </figcaption>
      </figure>
    </div>
  )
}

export default ContentImage
